/* eslint-disable semi-spacing,space-before-function-paren,quotes */
import http from '../common/Request'
import config from '../common/Config.js'
const GroupService = {
  /**
   * 获取店铺信息
   * @param groupId
   * @returns {Promise.<*>}
   */
  async doGetOneGroup(groupId) {
    var res = await http({
      url: "groupOpen_doGetOneGroup.do",
      method: "POST",
      data: {
        groupId: groupId
      },
	  bindToken:true
    })
    return res.data.data
  },
  //获取当前用户的顾问信息
  async doGetMyConsultant(groupId) {
    var res = await http({
      url: "customerService_doGetMyConsultant.do",
      method: "POST",
      data: {
        groupId: groupId
      }
    })
    return res.data
  },
  async doSearchNearGroups(params) {
    params.auditingStatus = 1;
    var res = await http({
      url: "group_doSearchNearGroups.do",
      method: "POST",
      data: params
    })
    return res.data
  },
  async doPageNearCompany(tradingAreaNo,location,radius,groupType,notGroupType,isShowGoods,page,count) {
    var httpBody = {
          url: "groupOpen_doPageNearCompany.do",
          method: "POST",
          data: {tradingAreaNo:tradingAreaNo,auditingStatus:1,location:location,radius:radius,groupType:groupType,notGroupType:notGroupType,isShowGoods:isShowGoods,page:page,count:count}
    };
    var res = await http(httpBody);
    return res.data
  },
  async doPageMongodbCompany(tradingAreaNo,groupType,notGroupType,isShowGoods,page,count) {
    var httpBody = {
          url: "groupOpen_doPageMongodbCompany.do",
          method: "POST",
          data: {tradingAreaNo:tradingAreaNo,auditingStatus:1,groupType:groupType,notGroupType:notGroupType,isShowGoods:isShowGoods,page:page,count:count}
    };
    var res = await http(httpBody);
    return res.data
  },
  async doAddUserGroup(params) {
      var res = await http({
          url: "group_doAddUserGroup.do",
          method: "POST",
          data:params 
      })
      return res.data
  },
    async doPageNear(param, page, count) {
        param.page = page;
        param.count = count;
        param.auditingStatus = 1;
        var httpBody = {
            url: "groupOpen_doPageNearCompany.do",
            method: "POST",
            data: param,
			bindToken:true
        };
        var res = await http(httpBody);
        return res.data
    },
    async doPage(param, page, count) {
        param.auditingStatus = 1;
        param.page = page;
        param.count = count;
        var httpBody = {
            url: "groupOpen_doPage.do",
            method: "POST",
            data: param
        };
        var res = await http(httpBody);
        return res.data
    },
    async doAdd(group) {
        var res = await http({
            url: "group_doAddCompany.do",
            method: "POST",
            data: group,
			bindToken:true
        })
        return res.data
    },
    async doUpdate(group) {
        var res = await http({
            url: "group_doUpdateGroup.do",
            method: "POST",
            data: group,
			bindToken:true
        })
        return res.data
    },
	async getGroupType(param) {
	    var res = await http({
	        url: "grouptype_doSearch.do",
	        method: "POST",
			data:param,
	    })
	    return res.data
	},
    async doValidateName(name) {
        var res = await http({
            url: "groupOpen_doValidateName.do",
            method: "POST",
            data:{name:name},
        })
        return res.data.data
    },
	async doAUDynamicFormItemValueRelations(form) {
		var res = await http({
			url: 'dyform_doAUDynamicFormItemValueRelations.do',
			data: form
		});
		return res;
	},

}
export default GroupService
