<template>
    <div class="empty-content">
        {{txt}}
    </div>
</template>
<script>
	export default {
		props: {
            txt: {
                type: String,
                default() {
                    return '暂无更多内容了';
                }
            },
		},
    }
    </script>
<style lang="scss">
    .empty-content {
        height:140px;
        width: 100%;
        line-height:140px;
        text-align:  center;
        font-size: 14px;
        color:#999;
    }
</style>