/* eslint-disable indent */
/**
 * Created by Admin on 2017/10/9.
 */
import http from '../common/Request'

const AddressService = {
    async doSearch(uid) {
        var res = await http({
            url: "deliver_address_doSearch.do",
            method: "POST",
            data: {uid: uid}
        });

        if (res.data.status == 1 && res.data.data) {
            return res.data.data;
        }
        return null;
    },
    //获取默认地址
    async doGetDefaultAddress(uid) {
        var res = await http({
            url: "deliver_address_getDefault.do",
            method: "POST",
            data: {uid: uid}
        });

        if (res.data.status == 1 && res.data.data) {
            return res.data.data;
        }
        return null;
    },
    async doWXChooseAddress(wxAddress) {
        var res = await http({
            url: "deliver_address_doWXChooseAddress.do",
            method: "POST",
            data: {
                receiveName: wxAddress.userName,
                province: wxAddress.provinceName,
                city: wxAddress.cityName,
                district: wxAddress.countyName,
                post: wxAddress.postalCode,
                detailAddress: wxAddress.detailInfo,
                mobile: wxAddress.telNumber,
                type: 1
            }
        })
        if (res.data.status == 1 && res.data.data) {
            return res.data.data;
        }
        return null;
    },
    async doAppChooseAddress(appAddress) {
        var res = await http({
            url: "deliver_address_doWXChooseAddress.do",
            method: "POST",
            data: appAddress
        })
        console.log(res);
        if (res.data.status == 1 && res.data.data) {
            return res.data.data;
        }
        return null;
    },
    //获取收货地址列表
    async deliver_address_list() {
        var res = await http({
            url: "deliver_address_list.do",
            method: "POST"
        })
        return res.data.data
    },
    /**
     * 获取城市区域信息
     */
    async area_doSearchTreeJson() {
        var res = await http({
            url: "area_doSearchTreeJson.do",
            method: "POST"
        })
        return res.data.data
    },
    /**
     * 获取详细的省城市区域信息
     */
    async area_doSearchAllTreeJson() {
        var res = await http({
            url: "area_doSearchAllTreeJson.do",
            method: "POST"
        })
        return res.data.data
    },
    /**
     * 获取区域下某个城市
     * @param areaId
     * @returns {Promise.<void>}
     */
    async area_searchCity(areaId) {
        var res = await http({
            url: "area_searchCity.do",
            method: "POST",
            data: {
                areaId: areaId
            }
        })
        return res.data.data
    },
    async deliver_address_add(objParam) {
        console.log({objParam: objParam})
        var res = await http({
            url: "deliver_address_add.do",
            method: "POST",
            data: {
                areaId: objParam.areaId,
                city: objParam.city,
                cityId: objParam.cityId,
                detailAddress: objParam.detailAddress,
                district: objParam.district,
                districtId: objParam.districtId,
                isDefault: objParam.isDefault,
                mobile: objParam.mobile,
                province: objParam.province,
                provinceId: objParam.provinceId,
                receiveName: objParam.receiveName,
                simpleAddress: objParam.simpleAddress,
                type: objParam.type,
                token: objParam.token
            }
        })
        return res.data
    },
    async deliver_address_doSetDefault(token, id) {
        var res = await http({
            url: "deliver_address_doSetDefault.do",
            method: "POST",
            data: {
                id: id,
                token: token
            }
        })
        return res.data
    },
    async deliver_address_doDel(token, id) {
        var res = await http({
            url: "deliver_address_doDel.do",
            method: "POST",
            data: {
                id: id,
                token: token
            }
        })
        return res.data
    },
    async deliver_address_edit(objParam) {
        var res = await http({
            url: "deliver_address_edit.do",
            method: "POST",
            data: {
                areaId: objParam.areaId,
                city: objParam.city,
                cityId: objParam.cityId,
                detailAddress: objParam.detailAddress,
                district: objParam.district,
                districtId: objParam.districtId,
                isDefault: objParam.isDefault,
                mobile: objParam.mobile,
                province: objParam.province,
                provinceId: objParam.provinceId,
                receiveName: objParam.receiveName,
                simpleAddress: objParam.simpleAddress,
                type: objParam.type,
                token: objParam.token,
                id: objParam.id
            }
        })
        return res.data;
    },
    async doGet(addressId) {
        var res = await http({
            url: "deliver_address_getById.do",
            method: "POST",
            data: {id: addressId}
        })
        if (res.data.status == 1 && res.data.data) {
            return res.data.data;
        }
        return null;
    },
}

export default AddressService
